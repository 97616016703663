<template>
  <div>
  <v-row no-gutters style="border-bottom: 1px solid #CCCCCC80">
    <v-col
      cols="12"
      md="5"
      style="padding:10px;text-align: center"
    >
      <b>
        {{ $t('booking.date', locale) }} 
      </b>
    </v-col>
    <v-col
      cols="12"
      md="5"
      style="padding:10px;text-align: center"
    >
      <b>
        {{ $t('booking.timetable', locale) }}
      </b>
    </v-col>
    <v-col
      cols="12"
      md="2"
    />
  </v-row>
  <draggable
    v-model="items"
    :draggable="'.itemDraggable'"
    style="width:100%;"
    v-bind="dragOptions"
    @start="handleSortStart"
    @end="handleSortItems(items)"
  >
    <v-row 
      v-for="item in items" 
      :key="item.id" 
      v-bind:class="'itemDraggable'"
      style="padding: 0;margin: 0;cursor: pointer;"
    >
    <v-col
      cols="12"
      md="5"
      style="text-align: center"
    >
      <v-icon>mdi-calendar</v-icon> {{item.Date}} 
    </v-col>
    <v-col
      cols="12"
      md="5"
      style="text-align: center"
    >
      <v-icon>mdi-clock-time-four</v-icon> {{convertTime(item.Time)}} - {{convertTime(item.Duration+item.Time)}}
    </v-col>
    <v-col
      cols="12"
      md="2"
      style="text-align: right;"
    >
      <v-icon
        small
        color="primary"
        @click="onEdit(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        color="red"
        @click="onDelete(item.ID)"
      >
        mdi-delete
      </v-icon>
    </v-col>
  </v-row>
  </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import utils from '@/services/utils'
export default {
  name: 'EventsList',
  components: {
    draggable,
  },
  props: {
    events: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  watch: {
    events () {
      this.items = this.events
    },
  },
  mounted () {
    this.items = this.events
  },
  methods: {
    convertTime (v) {
      return utils.convertTime(v).split(' ').join('')
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleSortItems (v) {
      console.log(v)
      /*this.data = v
      if (this.data && this.data.length)
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].ORD = i
        }*/
    },
    handleSortStart () {
    },
  }
}
</script>

